
body{
	margin: 0;
	padding: 0;
}

a:-webkit-any-link {
  text-decoration: none;
  color: white;
  cursor: pointer;
}

@font-face {
  font-family: 'space_grotesk', ;
  src: url('assets/fonts/SpaceGrotesk-Medium.ttf');
  font-weight: bold;
}
